import { Chip, Stack } from '@mui/material';

type Props<T extends { id?: string; name?: string }> = {
  data: T[];
  onClick?: (d: T) => void;
  unselected?: boolean;
  onDelete?: (d: T) => void;
  isDisabled?: boolean;
};

const PeparationIngredientsPicker = <T extends { id?: string; name?: string }>({
  data,
  onClick,
  onDelete,
  unselected,
  isDisabled,
}: Props<T>) => {
  const handleOnClick = (element: any) => {
    if (isDisabled) return;

    onClick?.(element);
  };

  const handleOnDelete = (element: any) => {
    if (isDisabled) return;

    onDelete?.(element);
  };

  return (
    <Stack direction='row' alignItems='center' flexWrap='wrap' gap={1}>
      {data
        ?.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        ?.map((i) => {
          return (
            <Chip
              data-testid='category-chip'
              key={i.id}
              onClick={() => handleOnClick(i)}
              onDelete={onDelete ? () => handleOnDelete(i) : undefined}
              label={i.name}
              sx={{
                color: 'kqn.darkerGray',
                borderRadius: '8px',
                bgcolor: unselected ? 'kqn.gray' : 'kqn.ultraLightCooper',

                '& .MuiChip-deleteIcon': {
                  color: 'kqn.copper',
                },
              }}
            />
          );
        })}
    </Stack>
  );
};

export default PeparationIngredientsPicker;
