import { Box } from '@mui/material';
import { Ingredient, RecipeGroupModel } from '../../../../../../../../types';
import GroupIngredientsTable from './group-ingredient-table';
import GroupTitleEditor from './group-title-editor';

type Props = {
  group: RecipeGroupModel;
  ingredients: Ingredient[];
  isLoading?: boolean;
};

const Group: React.FC<Props> = ({ group, ingredients, isLoading }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <GroupTitleEditor group={group} />
      <GroupIngredientsTable group={group} ingredients={ingredients} isLoading={isLoading} />
    </Box>
  );
};

export default Group;
