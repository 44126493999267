import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { Ordinal, RecipeIngredient } from '../../types';
import { groupService } from '../components/recipe-composer/components/ingredients/components/groups';

export const useUpdateGroupIngredientsOrdinals = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<
    unknown,
    unknown,
    { groupIngredientId?: string; args: Array<Ordinal> }
  >(({ args }) => groupService.updateGroupIngredientsOrdinals(args));

  const handleUpdateIngredientsOrdinals = async (ingredients: Array<RecipeIngredient>) => {
    const updated = ingredients.map((p, idx) => ({
      id: p.groupIngredientId || '',
      ordinal: idx + 1,
    }));

    mutation.mutate(
      { args: updated },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.groups.details._def);
          toast.success('Ingredient ordinals have been successfully updated.');
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleUpdateIngredientsOrdinals,
    isLoading: mutation.isLoading,
  };
};
