import MenuIcon from '@mui/icons-material/Menu';
import { CssBaseline, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '../../constants';
import { useAuthentication } from '../../context';
import { KoqoonLogo } from '../../shared';
import AnalitycsMenuItems from './analytics-menu-items';
import RecipeMenuItems from './recipe-menu-items';
import SidebarDrawer from './sidebar-drawer';
import SidebarMain from './sidebar-main';
import SidebarUserActions from './sidebar-user-actions';
import UserMenuItems from './user-menu-items';

interface Props {
  children: React.ReactNode;
}

const Sidebar: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { user, isLoading } = useAuthentication();
  const [open, setOpen] = useState(true);

  if (!user && !isLoading) return null;

  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SidebarDrawer open={open}>
        <Stack
          direction='row'
          gap={2}
          sx={{
            px: '20px',
            py: 2,
            justifyContent: open ? 'initial' : 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            sx={{ color: 'kqn.darkerGray', p: 0 }}
            size='large'
          >
            <MenuIcon />
          </IconButton>
          {open && (
            <KoqoonLogo
              color='#201D1D'
              onClick={() => navigate(NavigationPaths.DASHBOARD)}
              style={{ cursor: 'pointer', color: 'kqn.white' }}
            />
          )}
        </Stack>

        <RecipeMenuItems open={open} />
        <AnalitycsMenuItems open={open} />
        <UserMenuItems open={open} />
        <SidebarUserActions user={user} open={open} />
      </SidebarDrawer>
      <SidebarMain open={open}>{children}</SidebarMain>
    </Box>
  );
};

export default Sidebar;
