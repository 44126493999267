import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useGroupStore } from '../../../../../../../../store';
import { queries } from '../../../../../../../queries';
import Groups from './groups';

interface Props {
  recipeId: string;
}

const GroupsComposer: React.FC<Props> = ({ recipeId }) => {
  const { setGroups } = useGroupStore.use.actions();

  const { data, isLoading, isFetching } = useQuery({
    ...queries.groups.details(recipeId || undefined),
    enabled: !!recipeId,
  });

  useEffect(() => {
    if (data) {
      setGroups(data);
    }
  }, [data, setGroups]);

  return <Groups isLoading={isLoading || isFetching} />;
};

export default GroupsComposer;
