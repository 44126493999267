import { useQuery } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { koqoonApi } from '../../config';
import { useMounted } from '../../shared';
import { AuthUser, Credentials } from './authentication.types';
import { authenticationService } from './service';

interface AuthenticationContextState {
  user: AuthUser | undefined;
  isFetching: boolean;
  isLoading: boolean;
  setUser: (user: AuthUser | undefined) => void;
  signIn: (args: { credentials: Credentials; redirectPath?: string }) => void;
  signOut: (args?: { redirectPath?: string }) => void;
}

const authContextInitialState: AuthenticationContextState = {
  user: undefined,
  isFetching: false,
  isLoading: false,
  setUser: () => {},
  signIn: () => {},
  signOut: () => {},
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthenticationContext =
  createContext<AuthenticationContextState>(authContextInitialState);

export const AuthenticationProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);

  const { data, isFetching } = useQuery({
    queryFn: () => koqoonApi.get('/auth/me'),
    queryKey: ['auth/me'],
  });

  useEffect(() => {
    setIsLoading(true);
    if (isFetching) return;

    if (data) {
      setUser(data.data.user);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setUser(undefined);
  }, [data, isFetching]);

  const mounted = useMounted();

  const signOut = useCallback(
    async (args?: { redirectPath?: string }) => {
      await authenticationService.logout().then(() => {
        setUser(undefined);
      });

      if (args?.redirectPath) {
        navigate(args.redirectPath, { replace: true });
      }
    },
    [navigate],
  );

  const signIn = useCallback(
    async ({ credentials, redirectPath }: { credentials: Credentials; redirectPath?: string }) => {
      let authUser;
      try {
        authUser = await authenticationService.login(credentials);
      } catch (err: any) {
        toast.error(err);
      }

      if (!authUser) return;

      if (authUser.role === 'CUSTOMER' || authUser.role === 'TECHNICIAN') {
        toast.error('You are not authorized to access this application');
        signOut({});
        return;
      }

      setUser(authUser);
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      }
    },
    [navigate, signOut],
  );

  const value = useMemo(
    () => ({ user, setUser, signIn, signOut, isFetching, isLoading }),
    [isFetching, isLoading, signIn, signOut, user],
  );

  if (!mounted) return null;

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (!context) throw new Error('useAuthentication must be used within an AuthenticationProvider');

  return context;
};
