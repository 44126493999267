import { rectSortingStrategy } from '@dnd-kit/sortable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import { useCallback, useMemo } from 'react';
import { DragNDropContext } from '../../../../../../../../../components';
import { useHandleDragEnd } from '../../../../../../../../../shared';
import { useGroupStore } from '../../../../../../../../../store';
import { Ingredient, RecipeGroupModel, RecipeIngredient } from '../../../../../../../../types';
import { useUpdateGroupIngredientsOrdinals } from '../../../../../../../mutations';

import GroupIngredient from './group-ingredient';

type Props = {
  group: RecipeGroupModel;
  ingredients: Ingredient[];
  isLoading?: boolean;
};

const GroupIngredientsTable: React.FC<Props> = ({ ingredients, group, isLoading }) => {
  const { addIngredient, updateGroupIngredientsOrdinals } = useGroupStore.use.actions();
  const { handleUpdateIngredientsOrdinals } = useUpdateGroupIngredientsOrdinals();
  const hasDraft = useMemo(() => group.ingredients?.some((gi) => gi.isDraft), [group.ingredients]);

  const handleAddIngredient = useCallback(() => {
    addIngredient(group.id, {
      id: createId(),
      name: '',
      unitId: '',
      unit: '',
      quantity: 0,
      isDraft: true,
    });
  }, [addIngredient, group.id]);

  const handleOnUpdate = async (updated: RecipeIngredient[]) => {
    updateGroupIngredientsOrdinals(group.id, updated);
    await handleUpdateIngredientsOrdinals(updated);
  };

  const handleDragEnd = useHandleDragEnd<RecipeIngredient>({
    data: group.ingredients || [],
    onUpdate: handleOnUpdate,
  });

  return (
    <>
      <Table sx={{ maxWidth: 900 }} aria-labelledby='tableTitle' size='small'>
        <TableHead
          sx={{
            bgcolor: 'kqn.gray',
            '& th': { color: 'kqn.darkerGray' },
            '& th:first-of-type': { borderRadius: '2px 0 0 2px' },
            '& th:last-of-type': { borderRadius: '0 2px 2px 0' },
          }}
        >
          <TableRow>
            <TableCell>
              <Typography>№</Typography>
            </TableCell>
            <TableCell>
              <Typography>Ingredient</Typography>
            </TableCell>
            <TableCell>
              <Typography>Amount</Typography>
            </TableCell>
            <TableCell>
              <Typography>Unit</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Energy, kcal/100g</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Carbs, g/100g</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Protein, g/100g</Typography>
            </TableCell>
            <TableCell>
              <Typography align='center'>Fat, g/100g</Typography>
            </TableCell>
            <TableCell>
              <Box />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DragNDropContext
            items={group.ingredients}
            onDragEnd={handleDragEnd}
            strategy={rectSortingStrategy}
            isDisabled={hasDraft}
          >
            {group.ingredients.map((ingredient, index) => (
              <GroupIngredient
                key={ingredient.id}
                group={group}
                ordinal={index + 1}
                initialState={ingredient}
                ingredients={ingredients}
                isLoading={isLoading}
              />
            ))}
          </DragNDropContext>
        </TableBody>
      </Table>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{ color: 'kqn.darkGray' }}
      >
        <span
          onClick={handleAddIngredient}
          aria-hidden='true'
          data-testid='add-group-ingredient-button'
        >
          <AddCircleOutlineIcon sx={{ cursor: 'pointer', width: '24px', height: '24px', mt: 1 }} />
        </span>
      </Stack>
      <Divider sx={{ bgcolor: 'kqn.lightGray' }} />
    </>
  );
};
export default GroupIngredientsTable;
