import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useMealState } from '../../../../../../../../store';
import { FlowChart } from '../../../flow-chart';
import { MealCards, MealSteps } from './components';

type Props = {
  recipeId: string;
};

const Meals: React.FC<Props> = ({ recipeId }) => {
  const meals = useMealState();
  const [selected, setSelected] = useState<string | null>(null);
  const meal = useMemo(() => meals.find((m) => m.id === selected), [meals, selected]);

  const handleMealClick = useCallback((stepId: string) => {
    setSelected((prev) => (prev === stepId ? null : stepId));
  }, []);

  return (
    <Stack direction='row' gap={3}>
      <MealCards
        recipeId={recipeId}
        selected={selected}
        meals={meals}
        handleMealClick={handleMealClick}
      />
      <Stack gap={2} width='100%'>
        <FlowChart />
        {meal && <MealSteps meal={meal} />}
      </Stack>
    </Stack>
  );
};

export default Meals;
