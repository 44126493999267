import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../../../../../../../../../../shared';
import { Meal } from '../../../../../../../../../../../types';
import { calculateTotalDuration } from '../../helpers';
import MealComposer from '../../meal-composer';

interface Props {
  meal: Meal;
  recipeId: string;
  ordinal: number;
  isActive: boolean;
  onClick?: (stepId: string) => void;
}

const MealCard: React.FC<Props> = ({ isActive, meal, ordinal, recipeId, onClick }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: meal.id || '',
    transition: {
      duration: 1000, // milliseconds
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const {
    id,
    steps,
    mealType: { iconUri },
    timeOffset,
    title,
  } = meal;

  const handleOpenComposer = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (isOpen) return;

    onClick?.(id);
  };

  return (
    <Stack
      data-testid='meal-type-step-card'
      direction='column'
      onClick={handleOnClick}
      ref={setNodeRef}
      style={style}
      {...attributes}
      sx={{
        bgcolor: 'kqn.white',
        width: '240px',
        height: '120px',
        cursor: 'pointer',
        boxShadow: isActive
          ? '0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.14) inset,0px 2px 1px -1px rgba(0, 0, 0, 0.20) inset'
          : '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
      }}
    >
      <Stack direction='row' sx={{ p: 1, height: '100%' }} justifyContent='space-between'>
        <Stack direction='row' width='100%' gap={1} justifyContent='flex-start'>
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{ bgcolor: 'kqn.gray', borderRadius: '4px', p: 0.5, width: '50px', height: '45px' }}
          >
            <img src={iconUri} alt={title} className='meal-icon' />
          </Stack>
          <Typography variant='body1' width='100%'>
            {title}
          </Typography>
        </Stack>
        <Stack direction='column' gap={2} alignContent='center'>
          <DragIndicatorIcon {...listeners} sx={{ color: 'kqn.darkerGray', cursor: 'grab' }} />
          <EditIcon className='icon' onClick={handleOpenComposer} />
        </Stack>
      </Stack>
      <Stack
        sx={{
          color: '#000',
          bgcolor: colors[ordinal],
        }}
        direction='row'
        justifyContent='space-evenly'
        alignItems='end'
      >
        <Stack>MEAL {ordinal + 1}</Stack>
        <Stack direction='column' alignItems='center'>
          <Typography variant='caption'>Start offset, s</Typography>
          {timeOffset}
        </Stack>
        <Stack direction='column' alignItems='center'>
          <Typography variant='caption'>Duration, s</Typography>
          {calculateTotalDuration(steps)}
        </Stack>
      </Stack>
      <MealComposer
        recipeId={recipeId}
        meal={meal}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};

export default MealCard;
