import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Menu, MenuItem, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useGroupStore } from '../../../../../../../../store';
import { queries } from '../../../../../../../queries';
import { Group } from './components';
import { recipeGroups } from './config';

interface Props {
  isLoading?: boolean;
}

const Groups: React.FC<Props> = ({ isLoading }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { addGroup } = useGroupStore.use.actions();
  const groups = useGroupStore.use.groups();
  const isOpen = Boolean(anchorEl);

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddGroup = (name: string, nameDe: string) => {
    const alreadyExists = groups.find((group) => group.name === name);
    if (alreadyExists) return;

    addGroup({ name, nameDe });
    handleOnClose();
  };

  const { data: ingredients } = useQuery({
    ...queries.ingredients.filter({ query: '', perPage: 1000, page: 1 }),
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return (
    <Box gap={2} display='flex' flexDirection='column'>
      <Button
        id='action-btn'
        sx={{
          width: 'fit-content',
          bgcolor: 'kqn.darkerGray',
          color: '#fff',
          ':hover': { bgcolor: 'kqn.darkGray' },
          padding: '10px 15px',
        }}
        variant='contained'
        onClick={handleOpenMenu}
        aria-controls={isOpen ? 'action-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        data-testid='add-group-button'
      >
        INGREDIENT GROUP
      </Button>
      <Menu
        open={isOpen}
        id='action-menu'
        anchorEl={anchorEl}
        MenuListProps={{ 'aria-labelledby': 'action-btn' }}
        onClose={handleOnClose}
      >
        {recipeGroups.map(({ id, name, nameDe, icon }) => (
          <MenuItem key={id} value={name} onClick={() => handleAddGroup(name, nameDe)}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              data-testid='group-menu-item'
            >
              {name}
              {icon}
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <Stack direction='row' flexWrap='wrap' gap={3} justifyContent='flex-start'>
        {groups.map((group, index) => (
          <Group
            key={group.id || index}
            group={group}
            ingredients={ingredients?.data || []}
            isLoading={isLoading}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Groups;
