import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Typography } from '@mui/material';
import { NavigationPaths } from '../../constants';
import MenuListItems from './menu-list-items';
import { ListItemType } from './sidebar.types';

const menuItems: ListItemType[] = [
  {
    text: 'Reports',
    icon: <AssessmentOutlinedIcon />,
    url: NavigationPaths.ANALYTICS_REPORTS,
    testId: 'analytics-btn',
    path: ['analytics', 'reports', 'mobile-users', 'guided-mode', 'app-connections', 'app-usage'],
  },
];

type Props = {
  open: boolean;
};

const AnalitycsMenuItems: React.FC<Props> = ({ open }) => {
  return (
    <>
      {open && (
        <Typography
          px={2.5}
          py={1}
          variant='body1'
          align='left'
          sx={{ color: 'kqn.darkerGray', fontWeight: 600 }}
        >
          ANALYTICS
        </Typography>
      )}
      <MenuListItems data={menuItems} open={open} />
    </>
  );
};

export default AnalitycsMenuItems;
