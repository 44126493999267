import { ChartData, Node, NodeType } from '../chart-types';
import {
  calculateStartOffsetCorrection,
  calculateTotalDurationTime,
  generateBurnerNodes,
  generateNodeMetadata,
  getZoneNodes,
  isOverlapping,
  isSameMeal,
  isSameStepDifferentBurner,
  sortChartData,
} from '../helpers';

/**
 * This function is used to parse the data from the meals and return the sorted data and the total duration
 * which is used to render the chart and the timeline.
 * Node is the object that represent a step in the chart. It can be preheating or cooking.
 * @param data - The meals to parse
 * @returns sortedData and totalDuration - The sorted data and the total duration
 */
let firstNode: Partial<NodeType>;

export function parseData(data: ChartData[]) {
  const zones: Map<string, Node[]> = new Map();
  zones.set('Burner 1', []);
  zones.set('Burner 2', []);
  zones.set('Burner 3', []);
  zones.set('Burner 4', []);
  zones.set('Chamber', []);
  zones.set('User', []);

  const firstStep = data?.[0]?.steps?.[0];
  const startOffsetCorrection = calculateStartOffsetCorrection(data);
  let overlappingCount = 1;

  data.map((meal, idx) => {
    // Latest processed node in the current meal
    let latestMealNode: Node | undefined;

    for (let i = 0; i < meal.steps.length; i += 1) {
      const currentStep = meal.steps[i];
      if (!currentStep) return;

      const isFirst = i === 0;
      const name = `${idx + 1}-${i + 1}`;
      const { burners, fullDuration, node, prepInterval } = generateNodeMetadata({
        name,
        meal,
        isFirst,
        step: currentStep,
        currentOrdinal: idx,
        startOffsetCorrection,
      });

      if (!burners) return;

      const burnersWithNodesArr = generateBurnerNodes(burners, node);

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      burnersWithNodesArr.forEach(({ zone, node: burnerNode }) => {
        if (i === 0 && idx === 0) {
          firstNode = burnerNode;
        }

        const zoneArr = zones.get(zone);
        if (!zoneArr) return;

        const { lastCreatedNode: prevNode, prevCreatedNode } = getZoneNodes(zoneArr);

        if (isOverlapping(burnerNode, prevNode)) {
          if (isSameMeal(burnerNode, prevNode)) {
            if (isOverlapping(burnerNode, prevCreatedNode)) {
              const newOffset = latestMealNode
                ? latestMealNode?.endOffset || 0
                : prevNode?.endOffset || 0;
              const altNode = {
                ...burnerNode,
                startOffset: newOffset,
                endOffset: newOffset + fullDuration,
              };

              zones.set(`${zone}-${overlappingCount}`, [altNode]);
              overlappingCount += 1;
              latestMealNode = altNode;
              return;
            }
            if (isSameStepDifferentBurner(burnerNode, latestMealNode)) {
              const altNode = {
                ...burnerNode,
                startOffset: latestMealNode?.startOffset,
                endOffset: latestMealNode?.endOffset,
              };
              zones.set(zone, [...zoneArr, altNode]);

              latestMealNode = altNode;
              return;
            }

            if (isSameMeal(burnerNode, latestMealNode)) {
              const altNode = {
                ...burnerNode,
                startOffset: latestMealNode?.endOffset,
                endOffset: (latestMealNode?.endOffset || 0) + fullDuration,
              };
              zones.set(zone, [...zoneArr, altNode]);

              latestMealNode = altNode;
              return;
            }

            const newStartOffset = prevNode.endOffset || 0;
            const altNode = {
              ...burnerNode,
              startOffset: newStartOffset,
              endOffset: newStartOffset + fullDuration,
            };
            zones.set(zone, [...zoneArr, altNode]);
            latestMealNode = node;

            return;
          }

          if (isSameStepDifferentBurner(burnerNode, latestMealNode)) {
            const updatedNode = {
              ...burnerNode,
              startOffset: latestMealNode?.startOffset || 0,
              endOffset: latestMealNode?.endOffset,
            };

            if (isOverlapping(burnerNode, prevNode)) {
              if (zone === 'Chamber') {
                const alteredNode = {
                  ...burnerNode,
                  startOffset: latestMealNode?.startOffset || 0 - prepInterval,
                  endOffset: latestMealNode?.endOffset || 0 - prepInterval,
                };
                zones.set(`${zone}-${overlappingCount}`, [alteredNode]);
                latestMealNode = alteredNode;
                overlappingCount += 1;

                return;
              }

              // We need to check if the prevNode end offset is before current node start offset,
              // then if it is on left side, we substract prepInterval.
              // TODO: check to see if prepInterval can be substracted from the startOffset
              let startOffset = latestMealNode?.startOffset || 0;
              let endOffset = latestMealNode?.endOffset || 0;
              if ((prevNode?.endOffset || 0) < (burnerNode?.startOffset || 0)) {
                startOffset -= prepInterval;
                endOffset -= prepInterval;
              }

              const newNode = {
                ...burnerNode,
                startOffset,
                endOffset,
              };

              zones.set(`${zone}-${overlappingCount}`, [newNode]);
              overlappingCount += 1;
              latestMealNode = newNode;

              return;
            }

            zones.set(zone, [...zoneArr, updatedNode]);
            latestMealNode = updatedNode;

            return;
          }

          let startOffset = burnerNode.startOffset || 0;
          let endOffset = startOffset + fullDuration;

          // Reverted from https://koqoon.atlassian.net/browse/KA-1767
          // Removed check for same temperature values, but kept the calculation
          // Updated here: https://koqoon.atlassian.net/browse/KA-2451
          if (!isFirst) {
            const latestEndOffset = latestMealNode?.endOffset || prevNode?.endOffset || 0;
            const burnerStartOffset = burnerNode.startOffset || 0;

            startOffset = latestEndOffset || burnerStartOffset - prepInterval;
          }

          // Calculate endOffset based on startOffset and fullDuration
          endOffset = startOffset + fullDuration;

          const updatedNode = {
            ...burnerNode,
            startOffset,
            endOffset,
          };

          zones.set(`${zone}-${overlappingCount}`, [updatedNode]);
          overlappingCount += 1;
          latestMealNode = updatedNode;

          return;
        }

        if (isSameMeal(burnerNode, latestMealNode)) {
          if (latestMealNode?.name === burnerNode.name) {
            const updatedNode = {
              ...burnerNode,
              startOffset: latestMealNode?.startOffset,
              endOffset: latestMealNode?.endOffset,
            };
            zones.set(zone, [...zoneArr, updatedNode]);
            latestMealNode = updatedNode;

            return;
          }

          const newStartOffset = (latestMealNode?.endOffset || 0) - prepInterval;
          const updatedNode = {
            ...burnerNode,
            startOffset: newStartOffset,
            endOffset: newStartOffset + fullDuration,
          };
          zones.set(zone, [...zoneArr, updatedNode]);
          latestMealNode = updatedNode;

          return;
        }

        let startOffset = latestMealNode?.endOffset ?? node.startOffset ?? 0;

        if (isFirst && firstNode.mealId !== node.mealId) {
          const firstStartOffset = firstNode.startOffset ?? 0;
          const firstPrepInterval = firstNode.prepInterval ?? 0;
          const nodePrepInterval = node?.prepInterval ?? 0;

          startOffset +=
            node.startOffset !== 0 ? 0 : firstStartOffset + firstPrepInterval - nodePrepInterval;
        }

        const endOffset = latestMealNode
          ? (latestMealNode.endOffset ?? 0) + fullDuration
          : startOffset + fullDuration;

        const updatedNode = {
          ...node,
          startOffset,
          endOffset,
          temperature: burnerNode.temperature,
        };

        zones.set(zone, [...zoneArr, updatedNode]);

        latestMealNode = updatedNode;
      });
    }

    return null;
  });

  const sortedData = sortChartData(zones);

  const totalDuration = calculateTotalDurationTime(sortedData, firstNode);
  return { sortedData, totalDuration };
}
