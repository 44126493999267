import { koqoonApi } from '../../../../../../../../../config';
import { Paths } from '../../../../../../../../../constants';
import { Ordinal, RecipeGroupModel } from '../../../../../../../../types';

const getRecipeGroups = async (recipeId?: string) => {
  if (!recipeId) return Promise.resolve([]);

  return koqoonApi.get(`${Paths.RECIPES}/${recipeId}${Paths.MEAL_GROUPS}`).then((response) => {
    return response.data as RecipeGroupModel[];
  });
};

const updateGroupIngredientsOrdinals = async (ordinals?: Ordinal[]) => {
  if (!ordinals) return Promise.resolve([]);

  try {
    const response = await koqoonApi.post(`${Paths.GROUPS}/ordinals`, {
      ordinals,
    });
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const groupService = {
  getRecipeGroups,
  updateGroupIngredientsOrdinals,
};
