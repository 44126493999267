import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import React, { useMemo } from 'react';
import { MainLayout } from '../../../../../../../components';
import { RecipePreparation } from '../../../../../../types';
import { useGetPreparations } from '../hooks';
import Preparations from '../preparations';

interface Props {
  recipeId: string | null;
}

const PreparationComposer: React.FC<Props> = ({ recipeId }) => {
  const { data, isLoading } = useGetPreparations(recipeId);
  const [preparations, setPreparations] = React.useState<RecipePreparation[]>([]);
  const hasDraft = useMemo(() => preparations.some((p) => p.isDraft), [preparations]);

  React.useEffect(() => {
    if (data) {
      setPreparations(data);
    }
  }, [data]);

  const handleAddPreparation = () => {
    if (hasDraft) return;

    const newPreparation: RecipePreparation = {
      id: createId(),
      description: '',
      descriptionDe: '',
      isNew: true,
      isDraft: true,
    };

    setPreparations((prev) => {
      return [...prev, newPreparation];
    });
  };

  const handleRemovePreparation = (id: string) => {
    setPreparations((prev) => {
      return prev.filter((p) => p.id !== id);
    });
  };

  if (!recipeId) return null;

  return (
    <MainLayout>
      <Stack direction='column'>
        <Stack gap={1} direction='column' justifyContent='flex-start'>
          <Button
            onClick={handleAddPreparation}
            type='button'
            data-testid='add-preparation-button'
            disabled={hasDraft || isLoading}
            sx={{
              width: 'fit-content',
              color: 'kqn.white',
              bgcolor: 'kqn.darkerGray',
              '&:hover': { bgcolor: 'kqn.darkGray' },

              '&:disabled': { bgcolor: 'kqn.darkGray', color: 'kqn.white' },
            }}
          >
            <AddIcon sx={{ color: 'kqn.white', mr: 1, width: '20px', height: '20px' }} />
            ADD
          </Button>
          <Preparations
            recipeId={recipeId}
            preparations={preparations}
            onUpdate={setPreparations}
            onRemove={handleRemovePreparation}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default PreparationComposer;
