import { Chip, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { KQNCheckbox, TableCellStyled } from '../../../../components';
import { sortableParams } from '../../../../components/table/hooks/use-table-sort';
import { getComparator, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { PageableUser, UsersHeadCells } from '../../../types';

type Props = {
  data: PaginatedResult<PageableUser>;
  selected: string[];
  perPage: number;
  order: 'asc' | 'desc';
  orderBy: keyof UsersHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const UserTableBody: React.FC<Props> = ({
  data,
  perPage,
  order,
  orderBy,
  selected,
  handleOnSelect,
}) => {
  const { data: users } = data;
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const records = !sortableParams.includes(orderBy)
    ? stableSort(users, getComparator(order, orderBy))
    : users;

  const rows = useMemo(() => records.slice(0, perPage), [perPage, records]);

  return (
    <TableBody data-testid='table-body'>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const active = row.active === 'active' ? 1 : 0;

        return (
          <TableRow
            hover
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.email}
            selected={isItemSelected}
            sx={{
              cursor: 'pointer',
              '&.Mui-selected': {
                bgcolor: 'kqn.ultraLightCopper',
              },

              '&.Mui-selected:hover': {
                bgcolor: 'kqn.ultraLightCopper',
              },
            }}
          >
            <TableCellStyled active={active} padding='checkbox'>
              <KQNCheckbox
                onClick={(event) => handleOnSelect(event, row.id)}
                checked={isItemSelected}
                labelId={labelId}
              />
            </TableCellStyled>
            <TableCellStyled active={active} component='th' id={labelId} scope='row'>
              {row.name}
            </TableCellStyled>
            <TableCellStyled active={active}>{row.lastName}</TableCellStyled>
            <TableCellStyled active={active}>{row.email}</TableCellStyled>
            <TableCellStyled active={active}>{row.role}</TableCellStyled>
            <TableCell>
              <Stack direction='row' gap={0.5} alignItems='center'>
                {row.providers.split(',').map((provider) => {
                  if (!provider) return null;
                  const isKoqoon = provider.trim() === 'Koqoon';
                  return (
                    <Chip
                      key={provider}
                      label={provider}
                      sx={{
                        color: isKoqoon ? 'kqn.copper' : 'kqn.green',
                        borderRadius: '30px',
                        bgcolor: isKoqoon ? 'kqn.ultraLightCopper' : 'kqn.paleGreen',
                        textTransform: 'uppercase',
                      }}
                    />
                  );
                })}
              </Stack>
            </TableCell>
            <TableCell>
              <Chip
                label={active ? 'Active' : 'Inactive'}
                sx={{
                  color: active ? 'kqn.green' : 'kqn.darkGray',
                  borderRadius: '30px',
                  bgcolor: active ? 'kqn.paleGreen' : 'kqn.gray',
                }}
              />
            </TableCell>
            <TableCell>{row.createdAt}</TableCell>
            <TableCell>{row.updatedAt}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default UserTableBody;
