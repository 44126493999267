import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { queries } from '../../queries';
import { Ordinal, RecipePreparation } from '../../types';
import { preparationService } from '../components';

export const useUpdatePreparationsOrdinals = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<unknown, unknown, { args: Array<Ordinal> }>(({ args }) =>
    preparationService.updatePreparationsOrdinals(args),
  );

  const handleUpdatePreparationsOrdinals = async (preparations: Array<RecipePreparation>) => {
    const updated = preparations.map((p, idx) => ({ id: p.id || '', ordinal: idx + 1 }));
    mutation.mutate(
      { args: updated },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.preparations.all._def);
          toast.success('Preparation ordinals updated successfully');
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    mutation,
    handleUpdatePreparationsOrdinals,
    isLoading: mutation.isLoading,
  };
};
