import { format } from 'date-fns';
import { PaginatedResult } from '../../../types';
import { UserResponse } from '../../types';

export const mapUsersResponseToObject = (users?: PaginatedResult<UserResponse>) => {
  const defaultMeta = {
    total: 10,
    lastPage: 1,
    currentPage: 1,
    perPage: 10,
    prev: null,
    next: null,
  };

  const mapped = (users?.data || []).map((user: UserResponse) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    name: user.firstName,
    providers: user.providers.map((provider) => provider.name).join(', '),
    email: user.email,
    role: user.role.name,
    active: user.active ? 'active' : 'inactive',
    createdAt: format(user.createdAt, 'dd/MM/yyyy'),
    updatedAt: format(user.updatedAt, 'dd/MM/yyyy'),
  }));

  return {
    meta: users?.meta || defaultMeta,
    data: mapped,
  };
};
