import { rectIntersection } from '@dnd-kit/core';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DragNDropContext, Optional } from '../../../../../../../../../../../../components';
import { useHandleDragEnd } from '../../../../../../../../../../../../shared';
import { useMealActions } from '../../../../../../../../../../../../store/meal-store';
import { Meal } from '../../../../../../../../../../../types';
import presets from '../../../../../preset/components/preset/presets';
import MealComposer from '../../meal-composer';
import { useUpdateMealsOrdinals } from '../../mutations';
import { MealCard, NewMealCard } from '../meal-card';

type Props = {
  recipeId: string;
  meals: Array<Meal>;
  selected?: string | null;
  handleMealClick: (mealId: string) => void;
};

const MealCards: React.FC<Props> = ({ recipeId, meals, selected, handleMealClick }) => {
  const { setData } = useMealActions();
  const [isOpen, setIsOpen] = useState(false);

  const { handleUpdateMealsOrdinals } = useUpdateMealsOrdinals();

  const handleOnUpdate = (updated: Meal[]) => {
    setData(updated);
    handleUpdateMealsOrdinals(updated);
  };

  const handleDragEnd = useHandleDragEnd<Meal>({
    data: meals || [],
    onUpdate: handleOnUpdate,
  });

  return (
    <Stack direction='column'>
      <Grid container spacing={2} direction='column'>
        <DragNDropContext
          items={meals}
          onDragEnd={handleDragEnd}
          collisionDetection={rectIntersection}
          strategy={verticalListSortingStrategy}
        >
          {meals?.map((m, idx) => {
            return (
              <Grid item key={m.id}>
                <MealCard
                  isActive={selected === m.id}
                  recipeId={recipeId}
                  meal={m}
                  ordinal={idx}
                  onClick={handleMealClick}
                />
              </Grid>
            );
          })}
        </DragNDropContext>
        <Grid item>
          <NewMealCard onClick={() => setIsOpen(true)} />
        </Grid>
      </Grid>
      <Optional condition={!presets.length}>
        <Typography variant='h6' align='center'>
          Please select a meal type step to add grill steps to it. You can add multiple grill steps
          to a meal type step.
        </Typography>
      </Optional>
      <MealComposer
        recipeId={recipeId}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};
export default MealCards;
